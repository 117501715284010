<template>
  <LoggersStyled>
    <PanelTableMolecule
      :isLoading="loading"
      :count="totalCount"
      :title="$tc('loggers')"
      :columns="columns"
      :rows="tableData"
      :pageIndex="page + 1"
      :pageSize="perPage"
      :createRouteName="'createLogger'"
      @pageNumberChange="pageNumberChange($event)"
      @reload="reload"
    />
    <router-view @reload="reload" />
  </LoggersStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'
import PanelTableMolecule from '@/components/Atomic/Molecules/PanelTableMolecule'
import { hasAnyTalpaRoleInToken } from '@common/utils'
import { Edit2Icon, SearchIcon } from 'vue-feather-icons'
import TableColumnSearch from '@/components/Atomic/Molecules/TableColumnSearch'
import LOGGERS_QUERY from '#/graphql/loggers/list.gql'
import EntityLinkAtom from '@/components/Atomic/Atoms/EntityLinkAtom'

const LoggersStyled = styled('div')`
  margin: 1rem;
`

export default {
  components: {
    LoggersStyled,
    PanelTableMolecule,
  },
  data() {
    return {
      loading: true,
      totalCount: 0,
      models: [],
      searchQuery: '',
      where: null,
      perPage: 10,
      page: 0,
    }
  },
  computed: {
    take() {
      return this.perPage
    },
    skip() {
      return this.page * this.perPage
    },
    hasWritePermission() {
      return hasAnyTalpaRoleInToken
    },
    editRoute() {
      if (this.hasWritePermission) {
        return 'logger'
      } else {
        return null
      }
    },
    createRoute() {
      if (this.hasWritePermission) {
        return 'createLogger'
      } else {
        return null
      }
    },
    columns() {
      const header = [
        {
          field: 'serialNumber',
          key: 'serialNumber',
          title: 'Serial Number',
          align: 'left',
          filterCustom: {
            defaultVisible: false,
            render: ({ closeFn }) => {
              return (
                <TableColumnSearch
                  searchValue={this.searchQuery}
                  placeholder="Search name"
                  on-valueChange={e => (this.searchQuery = e)}
                  on-cancel={() => this.searchCancel(closeFn)}
                  on-confirm={() => this.searchConfirm(closeFn)}
                />
              )
            },
            // custom filter icon
            filterIcon: () => {
              return <SearchIcon size="1.3x" />
            },
          },
          renderBodyCell: ({ row }) => {
            const { id, type, serialNumber } = row
            if (!id || !type || !serialNumber) {
              return `something missing`
            }
            return <EntityLinkAtom type={type} id={id} label={serialNumber} />
          },
        },
        { field: 'model', key: 'model', title: 'Model', align: 'center' },
        { field: 'name', key: 'name', title: 'Name', align: 'center' },
        { field: 'status', key: 'status', title: 'Status', align: 'center' },
        {
          field: 'actions',
          key: 'i',
          title: 'Actions',
          width: '',
          center: 'left',
          renderBodyCell: ({ row }) => {
            return (
              <span class="actions">
                <router-link to={`/loggers/${row?.rowKey}`}>
                  <Edit2Icon size="1.3x" />
                </router-link>
              </span>
            )
          },
        },
      ]
      return header
    },
    tableData() {
      const tableData = this.models.map(logger => {
        return {
          name: logger.brand,
          model: logger.model.name,
          serialNumber: logger.serialNumber,
          status: logger.status.title,
          rowKey: logger.id,
          id: logger.id,
          type: logger.__typename,
        }
      })
      return tableData
    },
  },
  methods: {
    reload() {
      this.searchQuery = null
      this.$apollo.queries.models.refetch()
    },
    pageNumberChange(pageIndex) {
      this.page = pageIndex - 1
    },
    searchCancel(closeFn) {
      this.searchQuery = ''
      closeFn()
    },
    searchConfirm(closeFn) {
      closeFn()
    },
  },
  watch: {
    searchQuery: {
      handler(s) {
        this.page = 0
        if (s) {
          this.where = {
            serialNumber: {
              contains: s,
            },
          }
        } else {
          this.where = undefined
        }
      },
    },
  },
  apollo: {
    models: {
      query: LOGGERS_QUERY,
      variables() {
        return {
          take: this.take,
          skip: this.skip,
          where: this.where,
        }
      },
      manual: true,
      result({ data, loading }) {
        this.loading = loading
        this.models = get(data, 'loggers', [])
        this.totalCount = get(data, 'loggersRecordCount', 0)
      },
    },
  },
}
</script>
